import * as firebase from "firebase/app";
import "firebase/auth";

import axios from "axios";
import { fraction } from "~/filters.js";
import { APIv1, rootAPI } from "~/config";
import { kmPerMile, milePerKm } from "~/constants";

//
// Setup API + Firebase token authentication
//

const api = axios.create(APIv1);
const rapi = axios.create(rootAPI);

let AUTH_TOKEN = "";
let AUTH_LAST = 0;

const TOKEN_TTL = 60 * 1000; // 60 seconds

function refreshAuthToken() {
  if (AUTH_LAST + TOKEN_TTL > Date.now()) {
    return new Promise((resolve) => resolve(AUTH_TOKEN));
  } else
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(function (token) {
        AUTH_TOKEN = token;
        AUTH_LAST = Date.now();

        // console.log('AUTH_TOKEN', AUTH_TOKEN)
      });
}

export function authToken() {
  return new Promise((resolve) =>
    refreshAuthToken().then(function () {
      resolve(AUTH_TOKEN);
    })
  );
}

api.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Firebase ${AUTH_TOKEN}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
rapi.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Firebase ${AUTH_TOKEN}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function promiseToGet(endpoint, success, failure) {
  // Is this a valid endpoint or just a bunch of local JSON files?
  if (APIv1.baseURL.indexOf("http") !== 0) {
    let argOffset = endpoint.indexOf("?");

    if (argOffset === -1) {
      endpoint += ".json";
    } else {
      endpoint = endpoint.slice(0, argOffset) + ".json" + endpoint.slice(argOffset);
    }
  }

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .get(endpoint, { timeout: 120000 }) // 120 seconds
        .then(function ({ data }) {
          success(data, resolve);
        })
        .catch(failure === undefined ? reject : failure);
    });
  });
}

function promiseToGetVersion(endpoint, version, success, failure) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      const config = {
        headers: { Accept: "application/io.ohme." + version + "+json" },
      };

      rapi
        .get(endpoint, config)
        .then(function ({ data }) {
          success(data, resolve);
        })
        .catch(failure === undefined ? reject : failure);
    });
  });
}

function promiseDummyData(data) {
  return new Promise(function (resolve) {
    resolve(data);
  });
}

export function resetAuthToken() {
  return new Promise(function (resolve) {
    AUTH_TOKEN = "";
    AUTH_LAST = 0;
    resolve();
  });
}

//
// WebSocket connection
//

const RECONNECTION_OFFLINE_THRESHOLD = 3;
const RECONNECTION_DELAY = 1000;
const PING_DELAY = 15000;

// Public interface
export function startFleetFeed(fleetId) {
  const feed = {
    tries: 0,
    listener: null,
    pinger: null,
    messages: [],
    fleetId: fleetId,
    ws: null,
  };

  attachFeedWebSocket(feed).then(() => {
    // console.log('WebSocket connection established')
  });

  return feed;
}

export function attachFleetFeedListener(feed, listener) {
  feed.listener = listener;
  feed.messages.forEach((m) => listener(m));
  feed.messages = [];
}

export function stopFleetFeed(feed) {
  if (feed == null || feed.ws == null) return;
  stopPinging(feed);
  feed.ws.close();
  feed.ws = null;
}

// Private methods
function restartFleetFeed(feed) {
  broadcastMessage(feed, { type: "reconnecting" });

  if (navigator.onLine === false) {
    restartFeedLater(feed);
  } else {
    attachFeedWebSocket(feed).then(() => {
      // console.log('WebSocket connection re-established')
      if (feed.tries >= RECONNECTION_OFFLINE_THRESHOLD) broadcastMessage(feed, { type: "online" });
      feed.tries = 0;
      broadcastMessage(feed, { type: "reconnected" });
    });
  }
}

function restartFeedLater(feed) {
  if (++feed.tries == RECONNECTION_OFFLINE_THRESHOLD) {
    broadcastMessage(feed, { type: "offline" });
  }
  setTimeout(() => restartFleetFeed(feed), RECONNECTION_DELAY);
}

function attachFeedWebSocket(feed) {
  return new Promise((resolve) => {
    feed.ws = new WebSocket(
      rootAPI.baseURL.replace("https://", "wss://").replace("ohme.io/", "ohme.io:1443/") +
        `v1/feeds/fleets/${feed.fleetId}`
    );

    feed.ws.addEventListener("open", function () {
      refreshAuthToken().then(function () {
        // Authenticate the feed
        feed.ws.send(
          JSON.stringify({
            token: AUTH_TOKEN,
          })
        );

        startPinging(feed);
        resolve(feed.ws);
      });
    });

    feed.ws.addEventListener("error", function (error) {
      // console.log('Some error occured', error)
    });

    feed.ws.addEventListener("message", function (event) {
      if (event.data === '"pong"') return;

      const data = parseWebSocketMessage(JSON.parse(event.data));

      if (data.errorCode != null) {
        console.error("WebSocket error:", data.message);
        stopFleetFeed(feed);
        return;
      }

      broadcastMessage(feed, data);
    });

    // Monitor connection exit condition
    feed.ws.addEventListener("close", function (event) {
      if (event.wasClean) return;

      if (feed.tries === 0) {
        // console.log('WebSocket connection died')
        stopPinging(feed);
        feed.ws = null;
      } else {
        // console.log('WebSocket re-connection failed:', feed.tries)
      }

      restartFeedLater(feed);
    });
  });
}

function startPinging(feed) {
  if (feed.pinger != null) stopPinging(feed);
  feed.pinger = setInterval(() => {
    // feed.ws.send('PING')
    feed.ws.send(
      JSON.stringify({
        token: "ping",
      })
    );
  }, PING_DELAY);
}

function stopPinging(feed) {
  if (feed == null || feed.pinger == null) return;
  clearInterval(feed.pinger);
  feed.pinger = null;
}

function broadcastMessage(feed, message) {
  // console.log('WebSocket message', message)

  // Forward to listener, if there is one
  if (feed.listener != null) {
    feed.listener(message);
  }
  // Buffer otherwise
  else {
    feed.messages.push(message);
  }
}

function parseWebSocketMessage(data) {
  if (data.errorCode != null) return data;
  else if (data.updateType != null)
    switch (data.updateType) {
      case "CHARGER":
        const c = data.chargerStatus;
        return {
          type: "socket_status",
          body: {
            id: c.socket.id,
            chargerId: c.chargerId,
            status: parseChargerSocketStatus(c.status),
          },
        };
      default:
        return {
          type: "unknown_message",
          body: data,
        };
    }
  else
    return {
      errorCode: 666,
      message: data,
    };
}

//
// 3rd party authentication
//
export function validateAuthenticationURL(client_id, url) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .get(`/oauth/clients/${client_id}/checkUrl?url=${url}`)
        .then(function ({ data }) {
          resolve({
            valid: data,
          });
        })
        .catch(reject);
    });
  });
}

export function getAuthenticationCode(client_id, scope) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .get(`/oauth/code?client_id=${client_id}&scope=${scope}`, {
          responseType: "text",
        })
        .then(function ({ data }) {
          resolve({
            code: data,
          });
        })
        .catch(reject);
    });
  });
}

//
// Stripe Payments
//

export function getStripeClientSecretForFleet(fleetId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/billing/paymentsource/directdebit/startsetup`, null, {
          responseType: "text",
        })
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

export function getDirectDebitInfo(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/billing/paymentsource/directdebit`, function (data, resolve) {
    const details = data.card || data.details;
    if (details != null)
      resolve({
        bankCode: details.bankCode,
        branchCode: details.branchCode,
        countryCode: details.country,
        last4Digits: details.last4Digits,
      });
    else
      resolve({
        bankCode: null,
        branchCode: null,
        countryCode: null,
        last4Digits: null,
      });
  });
}

export function setDefaultPaymentMethod(fleetId, methodId) {
  const gatewayId = "STRIPE_02";

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .put(
          `/fleets/${fleetId}/billing/paymentsource/setDefault?gatewayAccountId=${gatewayId}&paymentMethodId=${methodId}`
        )
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

//
// Data retrieval
//

export function getAccount() {
  return promiseToGet("/users/me/fleet/", function (data, resolve) {
    resolve({
      name: stringValue(data.firstName) + " " + stringValue(data.lastName),
      photo: data.imageUrl,
      fleetId: data.fleetId,
      currency: data.currency,
      metric: data.unitSystem.toLowerCase().indexOf("imperial") === -1,
    });
  });
}

export function getAdministrableFleets() {
  return promiseToGet("/users/me/fleets/administrable", function (data, resolve) {
    resolve(data.fleets.map((f) => parseSubFleet(f)));
  });
}

export function getFleet(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/`, function (data, resolve) {
    resolve(parseFleet(data));
  });
}

export function getFleetSetupState(fleetId) {
  return promiseToGet(`fleets/${fleetId}/setupState`, function (data, resolve) {
    resolve(parseFleetSetupState(data));
  });
}

export function getFleetReimbursementSettings(fleetId) {
  return promiseToGet(`fleets/${fleetId}/reimbursementSettings`, function (data, resolve) {
    resolve(parseReimbursementSettings(data));
  });
}

export function getDummyReimbursementSettings() {
  return promiseDummyData(parseReimbursementSettings(null));
}

export function getFleetDetails(fleetId) {
  return promiseToGet(`fleets/${fleetId}/details`, function (data, resolve) {
    resolve(parseFleetDetails(data));
  });
}

export function getMakeLogos() {
  return promiseToGet("/carBrands/", function (data, resolve) {
    const results = {};

    data.forEach(function (make) {
      results[make.name.toLowerCase()] = make.logoUrl;
    });

    resolve(results);
  });
}

export function getGroups(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/groups/`, function (data, resolve) {
    const results = [];

    data.groups.forEach(function (group) {
      results.push(parseGroup(group));
    });

    resolve(results);
  });
}

export function getChargerModels(fleetId) {
  return promiseToGetVersion(
    `/charger-management/charge-devices/models?assignedFleetId=${fleetId}`,
    "chargemanagement-v1",
    function (data, resolve) {
      resolve(data.models.map((m) => parseChargerModel(m)));
    }
  );
}

export function getChargers(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/chargeDevices/`, function (data, resolve) {
    resolve(data.chargeDevices.map((c) => parseCharger(c)));
  });
}

export function getStatusOfChargerSockets(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/chargeDevices/status`, function (data, resolve) {
    resolve(
      data.chargeDevicesStatus.map((c) => ({
        id: c.socketId,
        chargerId: c.chargerId,
        status: parseChargerSocketStatus(c.status),
      }))
    );
  });
}

export function getProducts(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/products/`, function (data, resolve) {
    resolve(data.products.map((p) => parseProduct(p)));
  });
}

export function getUsers(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/userdata/`, function (data, resolve) {
    resolve(data.map((u) => parseUser(u)));
  });
}

export function getSingleUser(fleetId, userId) {
  return promiseToGet(`/fleets/${fleetId}/user/${userId}/data`, function(data, resolve) {
    resolve(data);
  });
}

export function getSingleUserParsed(fleetId, userId) {
  return promiseToGet(`/fleets/${fleetId}/user/${userId}/data`, function(data, resolve) {
    resolve(parseUser(data));
  });
}

export function getStatistics(fleetId, imperial, from, until) {
  const maxAttempts = 20;
  const delayBetweenAttempts = 10000;

  function attemptToGetStats() {
    if (from == null) from = Date.now() - 30 * 24 * 60 * 60 * 1000;
    if (until == null) until = Date.now();

    const distanceCoefficient = imperial ? 1 / kmPerMile : 1;
    const perDistanceCoefficient = imperial ? 1 / milePerKm : 1;

    return promiseToGet(`/fleets/${fleetId}/stats?fromTs=${from}&untilTs=${until}`, function (data, resolve) {
      const results = {
        users: [],
        fleet: [],
        fleetWeek: {},
        fleetMonth: {},
      };

      //
      // Individual user stats
      //
      data.userStats.forEach(function (stat) {
        if (stat) {
          const u = {};
          u.userId = stat.user.id;

          // Stats that are period-specific

          const d = stat.userStats;

          u.cost = {
            total: numericalValue(d.costings.total),
            perDistance: numericalValue(d.costings.perKm) * perDistanceCoefficient,
            totalPersonal: numericalValue(d.costings.totalPersonal),
            totalBusiness: numericalValue(d.costings.totalBusiness),
            totalHomeCharge: numericalValue(d.costings.totalHomeCharge),
            totalWorkCharge: numericalValue(d.costings.totalWorkCharge),
            totalDestinationCharge: numericalValue(d.costings.totalDestinationCharge),
            iceSavings: numericalValue(d.costings.savedVsIce),
            ohmeSavings: numericalValue(d.costings.savedOhme),
          };
          u.energy = {
            total: numericalValue(d.wh.total),
            perDistance: numericalValue(d.wh.perKm) * perDistanceCoefficient,
            totalPersonal: numericalValue(d.wh.totalPersonal),
            totalBusiness: numericalValue(d.wh.totalBusiness),
            totalHomeCharge: numericalValue(d.wh.totalHomeCharge),
            totalWorkCharge: numericalValue(d.wh.totalWorkCharge),
            totalDestinationCharge: numericalValue(d.wh.totalDestinationCharge),
          };
          u.distance = {
            total: numericalValue(d.km.total) * distanceCoefficient,
            totalPersonal: numericalValue(d.km.totalPersonal) * distanceCoefficient,
            totalBusiness: numericalValue(d.km.totalBusiness) * distanceCoefficient,
            iceSavings: numericalValue(d.km.savedVsIce),
            ohmeSavings: numericalValue(d.km.savedOhme),
          };
          u.reportedDistance = {
            total: numericalValue(d.km_reported.total) * distanceCoefficient,
            totalPersonal: numericalValue(d.km_reported.totalPersonal) * distanceCoefficient,
            totalBusiness: numericalValue(d.km_reported.totalBusiness) * distanceCoefficient,
            iceSavings: numericalValue(d.km_reported.savedVsIce),
            ohmeSavings: numericalValue(d.km_reported.savedOhme),
          };
          u.co2 = {
            total: numericalValue(d.co2.total),
            perDistance: numericalValue(d.co2.perKm) * perDistanceCoefficient,
            totalPersonal: numericalValue(d.co2.totalPersonal),
            totalBusiness: numericalValue(d.co2.totalBusiness),
            totalHomeCharge: numericalValue(d.co2.totalHomeCharge),
            totalWorkCharge: numericalValue(d.co2.totalWorkCharge),
            totalDestinationCharge: numericalValue(d.co2.totalDestinationCharge),
            iceSavings: numericalValue(d.co2.savedVsIce),
            ohmeSavings: numericalValue(d.co2.savedOhme),
          };
          u.statsByUsage = {
            personal: {
              cost: stat.chargeTotalsByUse.personal.cost,
              distance: imperial ? stat.chargeTotalsByUse.personal.miles : stat.chargeTotalsByUse.personal.kms,
              cpd: imperial ? stat.chargeTotalsByUse.personal.costPerMile : stat.chargeTotalsByUse.personal.costPerKm,
            },
            business: {
              cost: stat.chargeTotalsByUse.business.cost,
              distance: imperial ? stat.chargeTotalsByUse.business.miles : stat.chargeTotalsByUse.business.kms,
              cpd: imperial ? stat.chargeTotalsByUse.business.costPerMile : stat.chargeTotalsByUse.business.costPerKm,
            },
          };

          // General stats (loaded once)

          const location = stat.accumulatedChargeTotalsByLocation;

          u.statsByLocationAndPeriod = {
            home: {
              week: {
                cost: location.home.week.cost,
                distance: imperial ? location.home.week.miles : location.home.week.kms,
                cpd: imperial ? location.home.week.costPerMile : location.home.week.costPerKm,
              },
              month: {
                cost: location.home.month.cost,
                distance: imperial ? location.home.month.miles : location.home.month.kms,
                cpd: imperial ? location.home.month.costPerMile : location.home.month.costPerKm,
              },
              year: {
                cost: location.home.year.cost,
                distance: imperial ? location.home.year.miles : location.home.year.kms,
                cpd: imperial ? location.home.year.costPerMile : location.home.year.costPerKm,
              },
            },
            work: {
              week: {
                cost: location.work.week.cost,
                distance: imperial ? location.work.week.miles : location.work.week.kms,
                cpd: imperial ? location.work.week.costPerMile : location.work.week.costPerKm,
              },
              month: {
                cost: location.work.month.cost,
                distance: imperial ? location.work.month.miles : location.work.month.kms,
                cpd: imperial ? location.work.month.costPerMile : location.work.month.costPerKm,
              },
              year: {
                cost: location.work.year.cost,
                distance: imperial ? location.work.year.miles : location.work.year.kms,
                cpd: imperial ? location.work.year.costPerMile : location.work.year.costPerKm,
              },
            },
            destination: {
              week: {
                cost: location.destination.week.cost,
                distance: imperial ? location.destination.week.miles : location.destination.week.kms,
                cpd: imperial ? location.destination.week.costPerMile : location.destination.week.costPerKm,
              },
              month: {
                cost: location.destination.month.cost,
                distance: imperial ? location.destination.month.miles : location.destination.month.kms,
                cpd: imperial ? location.destination.month.costPerMile : location.destination.month.costPerKm,
              },
              year: {
                cost: location.destination.year.cost,
                distance: imperial ? location.destination.year.miles : location.destination.year.kms,
                cpd: imperial ? location.destination.year.costPerMile : location.destination.year.costPerKm,
              },
            },
          };

          const usage = stat.accumulatedChargeTotalsByUse;

          u.statsByUsageAndPeriod = {
            personal: {
              week: {
                cost: usage.personal.week.cost,
                percent: usage.personal.week.percent / 100,
              },
              month: {
                cost: usage.personal.month.cost,
                percent: usage.personal.month.percent / 100,
              },
              year: {
                cost: usage.personal.year.cost,
                percent: usage.personal.year.percent / 100,
              },
            },
            business: {
              week: {
                cost: usage.business.week.cost,
                percent: usage.business.week.percent / 100,
              },
              month: {
                cost: usage.business.month.cost,
                percent: usage.business.month.percent / 100,
              },
              year: {
                cost: usage.business.year.cost,
                percent: usage.business.year.percent / 100,
              },
            },
          };

          results.users.push(u);
        }
      });

      //
      // Aggregate fleet stats
      //

      data.fleetStats.monthlyStats.forEach(function (stat) {
        const f = {};

        f.month = stat.name;

        f.cost = {
          total: numericalValue(stat.costings.total),
          totalChange: nullOrRatio(stat.costings.totalChangePercent),
          totalPersonal: numericalValue(stat.costings.totalPersonal),
          totalBusiness: numericalValue(stat.costings.totalBusiness),
          totalHomeCharge: numericalValue(stat.costings.totalHomeCharge),
          totalWorkCharge: numericalValue(stat.costings.totalWorkCharge),
          totalDestinationCharge: numericalValue(stat.costings.totalDestinationCharge),
          perCar: numericalValue(stat.costings.perCar),
          perCarChange: nullOrRatio(stat.costings.perCarChangePercent),
          perDistance: numericalValue(stat.costings.perKm) * perDistanceCoefficient,
          perDistanceChange: nullOrRatio(stat.costings.perKmChangePercent),
          iceSavings: numericalValue(stat.costings.savedVsIce),
          iceSavingsChange: nullOrRatio(stat.costings.savedVsIceChangePercent),
          ohmeSavings: numericalValue(stat.costings.savedOhme),
          ohmeSavingsChange: nullOrRatio(stat.costings.savedOhmeChangePercent),
        };

        f.energy = {
          total: numericalValue(stat.wh.total),
          totalChange: nullOrRatio(stat.wh.totalChangePercent),
          totalPersonal: numericalValue(stat.wh.totalPersonal),
          totalBusiness: numericalValue(stat.wh.totalBusiness),
          totalHomeCharge: numericalValue(stat.wh.totalHomeCharge),
          totalWorkCharge: numericalValue(stat.wh.totalWorkCharge),
          totalDestinationCharge: numericalValue(stat.wh.totalDestinationCharge),
          perCar: numericalValue(stat.wh.perCar),
          perCarChange: nullOrRatio(stat.wh.perCarChangePercent),
          perDistance: numericalValue(stat.wh.perKm) * perDistanceCoefficient,
          perDistanceChange: nullOrRatio(stat.wh.perKmChangePercent),
        };

        f.distance = {
          total: numericalValue(stat.km.total) * distanceCoefficient,
          totalChange: nullOrRatio(stat.km.totalChangePercent),
          totalPersonal: numericalValue(stat.km.totalPersonal) * distanceCoefficient,
          totalBusiness: numericalValue(stat.km.totalBusiness) * distanceCoefficient,
          perCar: numericalValue(stat.km.perCar) * distanceCoefficient,
          perCarChange: nullOrRatio(stat.km.perCarChangePercent),
          iceSavings: numericalValue(stat.km.savedVsIce),
          iceSavingsChange: nullOrRatio(stat.km.savedVsIceChangePercent),
          ohmeSavings: numericalValue(stat.km.savedOhme),
          ohmeSavingsChange: nullOrRatio(stat.km.savedOhmeChangePercent),
        };

        f.reportedDistance = {
          total: numericalValue(stat.km_reported.total) * distanceCoefficient,
          totalChange: nullOrRatio(stat.km_reported.totalChangePercent),
          totalPersonal: numericalValue(stat.km_reported.totalPersonal) * distanceCoefficient,
          totalBusiness: numericalValue(stat.km_reported.totalBusiness) * distanceCoefficient,
          perCar: numericalValue(stat.km_reported.perCar) * distanceCoefficient,
          perCarChange: nullOrRatio(stat.km_reported.perCarChangePercent),
          iceSavings: numericalValue(stat.km_reported.savedVsIce),
          iceSavingsChange: nullOrRatio(stat.km_reported.savedVsIceChangePercent),
          ohmeSavings: numericalValue(stat.km_reported.savedOhme),
          ohmeSavingsChange: nullOrRatio(stat.km_reported.savedOhmeChangePercent),
        };

        f.co2 = {
          total: numericalValue(stat.co2.total),
          totalChange: nullOrRatio(stat.co2.totalChangePercent),
          totalPersonal: numericalValue(stat.co2.totalPersonal),
          totalBusiness: numericalValue(stat.co2.totalBusiness),
          totalHomeCharge: numericalValue(stat.co2.totalHomeCharge),
          totalWorkCharge: numericalValue(stat.co2.totalWorkCharge),
          totalDestinationCharge: numericalValue(stat.co2.totalDestinationCharge),
          perCar: numericalValue(stat.co2.perCar),
          perCarChange: nullOrRatio(stat.co2.perCarChangePercent),
          perDistance: numericalValue(stat.co2.perKm) * perDistanceCoefficient,
          perDistanceChange: nullOrRatio(stat.co2.perKmChangePercent),
          iceSavings: numericalValue(stat.co2.savedVsIce),
          iceSavingsChange: nullOrRatio(stat.co2.savedVsIceChangePercent),
          ohmeSavings: numericalValue(stat.co2.savedOhme),
          ohmeSavingsChange: nullOrRatio(stat.co2.savedOhmeChangePercent),
        };

        results.fleet.push(f);
      });

      results.fleetWeek = {
        cost: {
          total: numericalValue(data.fleetStats.currentWeek.costings.total),
          totalChange: nullOrRatio(data.fleetStats.currentWeek.costings.totalChangePercent),
        },
        distance: {
          total: numericalValue(data.fleetStats.currentWeek.km.total) * distanceCoefficient,
          totalChange: nullOrRatio(data.fleetStats.currentWeek.km.totalChangePercent),
        },
      };

      results.fleetMonth = {
        cost: {
          total: numericalValue(data.fleetStats.currentMonth.costings.total),
          totalChange: nullOrRatio(data.fleetStats.currentMonth.costings.totalChangePercent),
        },
        distance: {
          total: numericalValue(data.fleetStats.currentMonth.km.total) * distanceCoefficient,
          totalChange: nullOrRatio(data.fleetStats.currentMonth.km.totalChangePercent),
        },
      };

      resolve(results);
    });
  }

  return new Promise((resolve, reject) => {
    let attempts = 0;

    function retry() {
      attemptToGetStats()
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          if (++attempts === maxAttempts) {
            reject(new Error("Max attempts reached."));
          } else {
            setTimeout(retry, delayBetweenAttempts);
          }
        });
    }

    retry();
  });
}

export function getTransactions(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/billing/transactions/`, function (data, resolve) {
    resolve(data.map((t) => parseTransaction(t)));
  });
}

export function getReimbursements(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/reimbursements/`, function (data, resolve) {
    resolve(data.reimbursements.map((r) => parseReimbursement(r)));
  });
}

export function getAdmins(fleetId) {
  return promiseToGet(`/fleets/${fleetId}/admins/`, function (data, resolve) {
    const results = [];

    data.fleetAdmins.forEach(function (admin) {
      results.push(parseAdmin(admin));
    });

    resolve(results);
  });
}

//
// Data transformation
//

function parseFleet(fleet) {
  return {
    id: fleet.id,
    name: fleet.name,
    currencyCode:
      fleet.settings != null && fleet.settings.displayCurrency != null ? fleet.settings.displayCurrency : null,
    features:
      fleet.features != null
        ? {
            suborganisations: !!fleet.features.subOrganisations, // Suborganisations
            // billing: !!fleet.features.manageBilling, // Manage package
            drivers: true, // !!fleet.features.manageDrivers, // Fleet (Corporate)
            chargers: !!fleet.features.manageChargePoints, // Charger management (Residential)
            paygProducts: !!fleet.features.paidCharging, // Manage package (PAYG)
            recurringProducts: !!fleet.features.manageSubscriptions, // Manage package (Recurring)
            reimbursements: !!fleet.features.reimbursements, // Reimbursments
            reimbursementSettings: false, // Reimbursments
            locations: !!fleet.features.locationServices, // Geolocation
            quicksight: !!fleet.features.quickSightDashboard, // QuickSight Dashboard
            allowUnlinking: true, // Always allow unlinking for all fleets.
          }
        : {
            suborganisations: false,
            // billing: false,
            drivers: true,
            chargers: false,
            paygProducts: false,
            recurringProducts: false,
            reimbursements: false,
            reimbursementSettings: false,
            locations: false,
            quicksight: false,
          },
    address: parseAddress(fleet.address),
    rootUserGroupId: fleet.rootUserGroupId,
    rootDeviceGroupId: fleet.rootDeviceGroupId,
  };
}

function parseSubFleet(fleet) {
  return {
    id: fleet.id,
    name: fleet.name,
    address: parseAddress(fleet.address),
    rootUserGroupId: fleet.rootUserGroupId,
    rootDeviceGroupId: fleet.rootDeviceGroupId,
  };
}

function prepareFleet(fleet) {
  return {
    name: fleet.name,
    rootUserGroupParentId: fleet.rootUserGroupParentId,
    rootDeviceGroupParentId: fleet.rootDeviceGroupParentId,
  };
}

function parseFleetSetupState(setup) {
  return {
    directDebit: !!setup.directDebitSetup,
    billing: !!setup.billingEnabled,
  };
}

function parseReimbursementSettings(settings) {
  const s = settings;

  return s == null
    ? {
        settlementType: "",
        reimbursementType: "",
        flatRatePerKWh: moneyValueIn(null, 1000),
      }
    : {
        settlementType: s.settlement === "BANK_TRANSFER" ? "bank" : s.settlement === "UTILITY" ? "utility" : "",
        reimbursementType:
          s.settlement !== "BANK_TRANSFER"
            ? ""
            : s.type === "FLAT_RATE"
            ? "flat-rate"
            : s.type === "MILEAGE"
            ? "mileage"
            : s.type === "ACTUAL_COST"
            ? "energy-cost"
            : "",
        flatRatePerKWh: moneyValueIn(s.flatRateAmount, 1000),
      };
}

function prepareReimbursementSettings(settings) {
  const s = settings;

  return {
    settlement: s.settlementType === "bank" ? "BANK_TRANSFER" : s.settlementType === "utility" ? "UTILITY" : null,
    type:
      s.settlementType !== "bank"
        ? null
        : s.reimbursementType === "flat-rate"
        ? "FLAT_RATE"
        : s.reimbursementType === "mileage"
        ? "MILEAGE"
        : s.reimbursementType === "energy-cost"
        ? "ACTUAL_COST"
        : null,
    flatRateAmount:
      s.reimbursementType === "flat-rate" && s.settlementType === "bank"
        ? moneyValueOut(s.flatRatePerKWh, 0.001)
        : null,
  };
}

function parseFleetDetails(details) {
  return details.mainAddress == null
    ? {
        name: details.fleet,
        contactName: "",
        contactEmail: "",
        phone: "",
        street1: "",
        street2: "",
        city: "",
        // country: '',
        postcode: "",
      }
    : {
        name: details.fleet,
        contactName: details.mainAddress.name,
        contactEmail: details.mainAddress.email,
        phone: details.mainAddress.phone,
        street1: details.mainAddress.street,
        street2: details.mainAddress.street2,
        // country: details.mainAddress.country_code,
        city: details.mainAddress.city,
        postcode: details.mainAddress.zip,
      };
}

function prepareFleetDetails(details) {
  return {
    fleet: details.name,
    name: details.contactName,

    mainAddress: {
      name: details.contactName,
      email: details.contactEmail,
      phone: details.phone,
      street: details.street1,
      street2: details.street2,
      city: details.city,
      zip: details.postcode,

      // country: '',
      // state: '',
      // country_code: details.country,
      // full_address: '',
    },
  };
}

function parseFullAddress(address) {
  return address == null
    ? {
        street1: "",
        street2: "",
        city: "",
        state: "",
        postcode: "",
        country: "",

        wholeAddress: "",
      }
    : {
        street1: stringValue(address.streetNumber),
        street2: stringValue(address.streetName),
        city: stringValue(address.city),
        state: stringValue(address.state),
        postcode: stringValue(address.postCode),
        country: stringValue(address.countryCode),

        wholeAddress: stringValue(address.wholeAddress),
      };
}

function prepareFullAddress(address) {
  return address == null
    ? null
    : {
        streetNumber: address.street1,
        streetName: address.street2,
        city: address.city,
        state: address.state,
        postCode: address.postcode,
        countryCode: address.country,

        wholeAddress: null,
      };
}

function parseAddress(address) {
  return address == null
    ? {
        streetNumber: "",
        streetName: "",
        city: "",
        postcode: "",
      }
    : {
        streetNumber: stringValue(address.streetNumber),
        streetName: stringValue(address.streetName),
        city: stringValue(address.city),
        postcode: stringValue(address.postCode),
      };
}

function prepareAddress(address) {
  return {
    streetNumber: address.streetNumber,
    streetName: address.streetName,
    city: address.city,
    postCode: address.postcode,
  };
}

function parseGroup(group) {
  return {
    id: group.id,
    name: group.groupName,
    parentId: group.parentId,
    type: group.type === "USER" ? "user" : "device",
  };
}

function prepareGroup(group) {
  return {
    groupName: group.name,
    parentId: group.parentId,
    type: group.type === "user" ? "USER" : "DEVICE",
  };
}

function parseChargerSocketStatus(status) {
  return status === "Available"
    ? "available"
    : status === "Preparing"
    ? "preparing"
    : status === "Charging"
    ? "charging"
    : status === "Finishing"
    ? "finishing"
    : status === "Reserved"
    ? "reserved"
    : status === "SuspendedEV" || status === "SuspendedEVSE"
    ? "suspended"
    : status === "Unavailable"
    ? "unavailable"
    : status === "Offline"
    ? "offline"
    : status === "Faulted"
    ? "error"
    : "offline";
}

function parseChargerSocket(socket) {
  return {
    id: stringValue(socket.id),
    externalId: stringValue(socket.externalId),
    status: "offline",
  };
}

function parseChargerLocationType(type) {
  type = stringValue(type).toLowerCase();
  return type === "workplace" || type === "private" || type === "public" ? type : "";
}

function parseChargerModel(model) {
  if (model == null) return null;

  return {
    id: model.chargerModelId,
    make: model.chargerMake,
    model: model.chargerModel,
    version: model.chargerVersion,
    sockets: numericalValue(model.noSockets),
    phases: stringValue(model.noPhases),
    maxPower: numericalValue(model.maxPower),
    connector: stringValue(model.connectorType),
  };
}

function parseCharger(charger) {
  const address = parseAddress(charger.address);

  const location =
    charger.location == null
      ? {
          latitude: 0,
          longitude: 0,
        }
      : {
          latitude: numericalValue(charger.location.lat),
          longitude: numericalValue(charger.location.long),
        };

  const chargerSockets = arrayValue(charger.sockets)
    .sort((a, b) => {
      const socketA = a.id.toLowerCase();
      const socketB = b.id.toLowerCase();
      return socketA < socketB ? -1 : socketA > socketB ? +1 : 0;
    })
    .map((s) => parseChargerSocket(s));

  return {
    id: charger.chargerId,
    sockets: chargerSockets,
    name: stringValue(charger.name),
    model: parseChargerModel(charger.chargerModel),
    groupIds: arrayValue(charger.groupIds),
    paygProductId: stringValue(charger.paygProductId),
    address: address,
    location: location,
    locationType: parseChargerLocationType(charger.spaceType),
    serialId: charger.chargerId,
    refreshInterval: numericalValue(charger.heartBeatInterval),
  };
}

function prepareChargerCommon(charger) {
  return {
    name: charger.name,
    groupIds: charger.groupIds,
    externalId: charger.externalId,
    paygProductId: charger.paygProductId,
    address: prepareAddress(charger.address),
    location:
      charger.location == null
        ? {
            latitude: null,
            longitude: null,
          }
        : {
            latitude: charger.location.latitude,
            longitude: charger.location.longitude,
          },
    spaceType: charger.locationType,
    refreshInterval: 5 * 60 * 1000,
  };
}

function parseProduct(product) {
  const p = product.product;
  const groupIds = arrayValue(product.associatedGroupIds);

  return {
    id: p.id,
    rank: numericalValue(p.rank),
    name: p.name,
    type: p.type === "SUBSCRIPTION" ? "recurring" : "payg",
    public: !!p.availableToPublicUsers,
    groupIds: groupIds,
    description: p.description,
    currencyCode: p.currencyCode,
    pricePerKWh: moneyValueIn(p.pricePerWh, 1000),
    plugInFee: moneyValueIn(p.plugInFee),
    joinFee: moneyValueIn(p.oneTimeFee),
    recurringFee: moneyValueIn(p.recurringFee),
    recurringPeriod:
      p.recurringPeriod === "DAILY"
        ? "daily"
        : p.recurringPeriod === "WEEKLY"
        ? "weekly"
        : p.recurringPeriod === "MONTHLY"
        ? "monthly"
        : p.recurringPeriod === "YEARLY"
        ? "yearly"
        : "",
  };
}

function prepareNewProduct(product) {
  const p = product;

  return {
    product: {
      name: p.name,
      rank: p.rank,
      type: p.type === "recurring" ? "SUBSCRIPTION" : "PAYG",
      availableToPublicUsers: p.public,
      description: p.description,
      currencyCode: p.currencyCode,
      pricePerWh: moneyValueOut(p.pricePerKWh, 0.001),
      plugInFee: moneyValueOut(p.plugInFee),
      oneTimeFee: p.type === "recurring" ? moneyValueOut(p.joinFee) : null,
      recurringFee: p.type === "recurring" ? moneyValueOut(p.recurringFee) : null,
      recurringPeriod:
        p.type !== "recurring"
          ? null
          : p.recurringPeriod === "daily"
          ? "DAILY"
          : p.recurringPeriod === "weekly"
          ? "WEEKLY"
          : p.recurringPeriod === "monthly"
          ? "MONTHLY"
          : p.recurringPeriod === "yearly"
          ? "YEARLY"
          : null,
    },
    associatedGroupIds: product.groupIds,
  };
}

function prepareExistingProduct(product) {
  const p = product;

  return {
    product: {
      id: p.id,
      name: p.name,
      rank: p.rank,
      availableToPublicUsers: p.public,
      description: p.description,
    },
    associatedGroupIds: product.groupIds,
  };
}

function removeModelYear(model, year) {
  year = "(" + year + ")";
  const index = model.indexOf(year);

  if (index !== -1) {
    return model.substring(0, index).trim();
  } else {
    return model;
  }
}

function parseUser(user) {
  // TODO: Break this parser down into parseUser / parseCar / parseTariff / etc
  const u = {};

  u.id = user.id;
  u.externalId = user.user.externalUserId;
  u.active = true;
  u.invited = user.id.indexOf("ohme_fleet_usr") === 0;
  u.name = stringValue(user.user.firstName) + " " + stringValue(user.user.lastName);
  u.photo = user.user.imageUrl;
  u.email = user.user.email;
  u.phone = user.user.phoneNumber;
  u.driverLicence = user.user.driverLicenseId;
  u.fuelCard = user.user.fuelCardId;
  u.RFID = user.user.idTag;
  u.groupIds = arrayValue(user.user.groupIds);

  u.homeAddress = parseFullAddress(user.homeAddress);
  u.officeAddress = parseFullAddress(user.officeAddress);

  if (user.car)
    u.car = {
      registration: user.car.licensePlateId,
      notes: user.car.userNotes,
      logo: user.car.model.imageUrl,
      make: user.car.model.make,
      model: removeModelYear(stringValue(user.car.model.modelName), stringValue(user.car.model.modelYear)),
      year: stringValue(user.car.model.modelYear),
    };
  else
    u.car = {
      registration: "",
      notes: "",
      logo: "",
      make: "",
      model: "",
      year: "",
    };

  if (user.tariff)
    u.tariff = {
      logo: user.tariff.logoUrl,
      name: user.tariff.tariffDisplayName,
      supplier: user.tariff.supplierDisplayName,
      currency: user.tariff.currencyUnit,
    };
  else
    u.tariff = {
      logo: "",
      name: "",
      supplier: "",
      currency: "",
    };

  if (user.cable)
    u.cable = {
      id: user.cable.id,
      type: user.cable.type,
    };
  else
    u.cable = {
      id: "",
      type: "",
    };

  u.reimbursementSettings = parseReimbursementSettings(user.user.reimbursementSettings.userReimbursementSettings);

  return u;
}

function parseTransaction(transaction) {
  const t = transaction;

  return {
    createdOn: timestamp(t.paymentInitiatedTs),
    completedOn: timestamp(t.paymentCompletedTs),
    description: t.description,
    amount: moneyValueIn(t.amount),
    invoiceId: t.invoiceId,
    status:
      t.status === "COMPLETE"
        ? "complete"
        : t.status === "PROCESSING"
        ? "processing"
        : t.status === "FAILED"
        ? "failed"
        : "unknown",
  };
}

function parseReimbursement(reimbursement) {
  const r = reimbursement;

  return {
    startedOn: timestamp(r.periodStart),
    endedOn: timestamp(r.periodEnd),
    amount: moneyValueIn(r.money),
    ratePerKWh: moneyValueIn(r.ratePerWh, 1000),
    quantity: numericalValue(r.whCharged),
    user: {
      id: r.userId,
      firstName: stringValue(r.firstName),
      lastName: stringValue(r.lastName),
    },
    statusUpdatedOn: timestamp(r.lastStatusChangeTs),
    status:
      r.status === "COMPLETE"
        ? "complete"
        : r.status === "PROCESSING"
        ? "processing"
        : r.status === "PENDING"
        ? "pending"
        : r.status === "FAILED"
        ? "failed"
        : "unknown",
  };
}

function parseAdmin(admin) {
  const a = admin;

  return {
    id: a.id,
    active: true,
    invited: admin.id.indexOf("ohme_fleet_usr") === 0,
    name: stringValue(a.fullName),
    email: stringValue(a.email),
  };
}

function prepareAdminInvitation(admin) {
  const a = admin;

  return {
    name: a.name,
    email: a.email,
  };
}

function parseAdminInvitation(invitation) {
  const i = invitation;

  return {
    link: stringValue(i.link),
    type: i.type === "ADDITIONAL_NEW_ADMIN" ? "new" : i.type === "EXISTING_USER_ADMIN" ? "existing" : "unknown",
  };
}

//
// Data update
//
export function updateFleetDetails(fleetId, details) {
  const payload = prepareFleetDetails(details);

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/details`, payload)
        .then(function ({ data }) {
          // FIXME: The server response should return cannanical data:
          // resolve(parseFleetDetails(data))
          resolve(parseFleetDetails(payload));
        })
        .catch(reject);
    });
  });
}

export function createFleet(fleetId, fleet) {
  const payload = {
    fleet: prepareFleet(fleet),
  };

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/children/`, payload)
        .then(function ({ data }) {
          resolve(parseFleet(data.fleet));
        })
        .catch((error) => reject(genericError(error)));
    });
  });
}

function splitName(name) {
  const splitOffset = name.trim().indexOf(" ");

  if (splitOffset < 0)
    return {
      first: name,
      last: "",
    };
  else
    return {
      first: name.substring(0, splitOffset),
      last: name.substring(splitOffset + 1),
    };
}

export function createUser(fleetId, user) {
  const name = splitName(user.name);

  const data = {
    user: {
      externalUserId: user.externalId,
      email: user.email,
      phoneNumber: user.phone,
      firstName: name.first,
      lastName: name.last,
      imageUrl: null,
      driverLicenseId: user.driverLicence,
      fuelCardId: user.fuelCard,
      idTag: user.RFID,
      groupIds: user.groupIds,
    },
    car: null,
    tariff: null,
    cable: user.cableId
      ? {
          id: user.cableId,
        }
      : null,
    homeAddress: prepareFullAddress(user.homeAddress),
    officeAddress: prepareFullAddress(user.officeAddress),
  };

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/users/`, data)
        .then(function ({ data }) {
          resolve(data);
        })
        .catch((error) => reject(userError(error)));
    });
  });
}

export function updateUser(fleetId, user) {
  const userId = user.id;

  return new Promise(function (resolve, reject) {
    Promise.all([refreshAuthToken(), getSingleUser(fleetId, userId)]).then(function ([_, raw]) {
      const name = splitName(user.name);

      raw.user.externalUserId = user.externalId;
      raw.user.email = user.email;
      raw.user.phoneNumber = user.phone;
      raw.user.firstName = name.first;
      raw.user.lastName = name.last;
      raw.user.driverLicenseId = user.driverLicence;
      raw.user.fuelCardId = user.fuelCard;
      raw.user.idTag = user.RFID;
      raw.user.groupIds = user.groupIds;

      if (raw.car) {
        raw.car.licensePlateId = user.car.registration;
        raw.car.userNotes = user.car.notes;
      }

      if (user.cableId != null) {
        raw.cable = {
          id: user.cableId,
        };
      }

      raw.homeAddress = prepareFullAddress(user.homeAddress);
      raw.officeAddress = prepareFullAddress(user.officeAddress);

      api
        .put(`/fleets/${fleetId}/users/${userId}/`, raw)
        .then(function ({ data }) {
          resolve(data);
        })
        .catch((error) => reject(userError(error)));
    });
  });
}

export function updateUserEmail(fleetId, userId, email) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .patch(`fleets/${fleetId}/users/${userId}${queryString({ email })}`)
        .then(function ({ data }) {
          resolve(data);
        })
        .catch((error) => reject(userError(error)));
    });
  });
}

export function updateFleetReimbursementSettings(fleetId, settings) {
  const payload = prepareReimbursementSettings(settings);

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .patch(`fleets/${fleetId}/reimbursementSettings`, payload)
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

export function updateUserReimbursementSettings(fleetId, userId, settings) {
  const payload = prepareReimbursementSettings(settings);

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .patch(`fleets/${fleetId}/users/${userId}/reimbursementSettings`, payload)
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

export function createGroup(fleetId, group) {
  const payload = {
    group: prepareGroup(group),
  };

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/groups/`, payload)
        .then(function ({ data }) {
          resolve(parseGroup(data.group));
        })
        .catch((error) => reject(genericError(error)));
    });
  });
}

export function updateGroup(fleetId, group) {
  const groupId = group.id;
  const payload = {
    group: prepareGroup(group),
  };

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .put(`/fleets/${fleetId}/groups/${groupId}/`, payload)
        .then(function ({ data }) {
          resolve(parseGroup(data.group));
        })
        .catch((error) => reject(genericError(error)));
    });
  });
}

export function createCharger(fleetId, charger) {
  const payload = prepareChargerCommon(charger);

  payload["chargerModelId"] = charger["chargerModelId"];
  payload["cableId"] = charger["serialId"];
  payload["socketIds"] = {};
  charger["socketIds"].forEach((id, i) => {
    payload["socketIds"][i + 1 + ""] = id;
  });

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/chargeDevices/`, payload)
        .then(function ({ data }) {
          resolve(parseCharger(data));
        })
        .catch((error) => reject(genericError(error)));
    });
  });
}

export function updateCharger(fleetId, charger) {
  const payload = prepareChargerCommon(charger);
  const sockets = charger["sockets"];

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      const requests = [];
      sockets.forEach((s) => {
        const p = Object.assign({}, payload);
        p["externalId"] = s.externalId;
        requests.push(api.put(`/fleets/${fleetId}/chargeDevices/${s.id}/`, p));
      });
      Promise.all(requests)
        .then(function (responses) {
          // FIXME: Pull sockets from each corresponding response
          const canonical = responses[responses.length - 1].data;
          canonical["sockets"] = sockets;
          resolve(parseCharger(canonical));
        })
        .catch((error) => reject(genericError(error)));
    });
  });
}

export const unlinkCharger = (fleetId, chargerId, userId) => {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .patch(`/fleets/${fleetId}/users/${userId}/unlink/device/${chargerId}`)
        .then(function ({ data }) {
          resolve(data);
        })
        .catch((error) => reject(genericError(error)));
    });
  });
};

export function createProduct(fleetId, product) {
  const payload = prepareNewProduct(product);

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/products/`, payload)
        .then(function ({ data }) {
          resolve(parseProduct(data));
        })
        .catch((error) => reject(genericError(error)));
    });
  });
}

export function updateProduct(fleetId, product) {
  const productId = product.id;
  const payload = prepareExistingProduct(product);

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .put(`/fleets/${fleetId}/products/${productId}/`, payload)
        .then(function ({ data }) {
          resolve(parseProduct(data));
        })
        .catch((error) => reject(genericError(error)));
    });
  });
}

export function inviteAdmin(fleetId, admin) {
  const payload = prepareAdminInvitation(admin);

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/adminUser/`, payload)
        .then(function ({ data }) {
          resolve(parseAdminInvitation(data));
        })
        .catch((error) => reject(userError(error)));
    });
  });
}

//
// Data removal
//

export function deleteGroup(fleetId, groupId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .delete(`/fleets/${fleetId}/groups/${groupId}/`)
        .then(function () {
          resolve(groupId);
        })
        .catch(reject);
    });
  });
}

export function deleteCharger(fleetId, chargerId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .delete(`/fleets/${fleetId}/chargeDevices/${chargerId}/`)
        .then(function () {
          resolve(chargerId);
        })
        .catch(reject);
    });
  });
}

export function deleteUser(fleetId, userId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .delete(`/fleets/${fleetId}/users/${userId}/`)
        .then(function () {
          resolve(userId);
        })
        .catch(reject);
    });
  });
}

//
// Data export
//

export function exportUserData(fleetId, format) {
  if (format == null) format = "CSV";

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .get(`/fleets/${fleetId}/export/${format}/users/`, {
          responseType: "text",
        })
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

export function exportUserStatistics(fleetId, userIds, format, from, until) {
  if (from == null) from = Date.now() - 30 * 24 * 60 * 60 * 1000;
  if (until == null) until = Date.now();
  if (format == null) format = "CSV";

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .get(`/fleets/${fleetId}/export/${format}/users/stats/?fromTs=${from}&untilTs=${until}&userIds=${userIds}`, {
          responseType: "text",
        })
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

export function exportUserStatisticsSummary(fleetId, format, from, until) {
  if (from == null) from = Date.now() - 30 * 24 * 60 * 60 * 1000;
  if (until == null) until = Date.now();
  if (format == null) format = "CSV";

  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .get(`/fleets/${fleetId}/export/${format}/users/stats_summary/?fromTs=${from}&untilTs=${until}`, {
          responseType: "text",
        })
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

export function exportTransactionInvoice(fleetId, invoiceId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .get(`/fleets/${fleetId}/billing/transactions/invoice/${invoiceId}/`, {
          responseType: "text",
        })
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

//
// Misc
//

export function resendDriverInvitation(fleetId, userId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .post(`/fleets/${fleetId}/users/${userId}/resendInvitation`, {})
        .then(function ({ data }) {
          resolve(data);
        })
        .catch(reject);
    });
  });
}

export function unlockChargerSocket(fleetId, socketId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .put(`/fleets/${fleetId}/chargeDevices/${socketId}/unlock`, {})
        .then(function ({ data }) {
          data.unlockStatus === "Unlocked" ? resolve(data) : reject(data);
        })
        .catch(reject);
    });
  });
}

export function rebootChargerHard(fleetId, socketId) {
  return new Promise(function (resolve, reject) {
    refreshAuthToken().then(function () {
      api
        .put(`/fleets/${fleetId}/chargeDevices/${socketId}/reset`, { resetType: "Hard" })
        .then(function ({ data }) {
          data.resetStatus === "Accepted" ? resolve(data) : reject(data);
        })
        .catch(reject);
    });
  });
}

//
// Error handling
//

function genericError(error) {
  const r = {
    field: null,
    message: "Internal system error",
  };

  const e = error.response ? error.response.data : error;
  r.message = e.message;

  return r;
}

function userError(error) {
  const r = {
    field: null,
    message: "Internal system error",
  };

  const e = error.response ? error.response.data : error;
  r.message = e.message;

  switch (e.ohme_error_value) {
    case 201:
    case 203:
    case "EXISTS_ALREADY":
    case "TOO_MANY_INSTANCES":
      r.field = "email";
      r.message = "User with this email already exists";
      break;
    case 202:
    case "IS_IN_DIFFERENT_FLEET":
      r.field = "email";
      r.message = "User with this email already belongs to a different fleet";
      break;
    case "ADMIN_ALREADY":
      r.field = "email";
      r.message = "Admin with this email already exists";
      break;
    case 200:
      // Only not fully registered users can edit their emails
      break;
    case "CREATION_FAILED":
      // Creation of a totally new user failed
      break;
    case 300:
      r.field = "email";
      r.message = "Invalid email";
      break;
    case "MISSING_EMAIL":
      r.field = "email";
      r.message = "Email is required";
      break;
    case "DEVICE_DOES_NOT_EXIST":
    case "INVALID_CABLE_ID":
      r.field = "cableId";
      r.message = "Invalid cable ID";
      break;
    case "DEVICE_ALREADY_LINKED":
      r.field = "cableId";
      r.message = "This cable ID is already in use";
      break;
  }

  return r;
}

//
// Utilities
//

function queryString(params) {
  const parts = [];
  for (let p in params) {
    if (params.hasOwnProperty(p)) {
      parts.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
  }
  return parts.length > 0 ? "?" + parts.join("&") : "";
}

function nullOrRatio(value) {
  return isNaN(value) || value == null ? null : value / 100;
}

function stringValue(value) {
  return value == null ? "" : "" + value;
}

function arrayValue(value) {
  return value == null ? [] : value;
}

function numericalValue(value) {
  return isNaN(value) ? 0 : value;
}

function timestamp(value, ms = true) {
  return isNaN(value) ? 0 : ms === true ? value : value * 1000;
}

function moneyValueIn(value, conversion = 1.0) {
  return value == null
    ? {
        currency: null,
        amount: 0.0,
      }
    : {
        currency: value.currencyCode,
        amount: (parseFloat(value.amount) / 100) * conversion,
      };
}

function moneyValueOut(value, conversion = 1.0) {
  return value == null
    ? null
    : {
        currencyCode: value.currency,
        amount: fraction(value.amount * 100 * conversion, 6),
      };
}

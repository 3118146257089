<template>
  <main>
    <header>
      <h1>Driver management</h1>

      <div class="actions">
        <button @click="downloadUserData" class="action secondary">Export data</button>
        <router-link class="action" tag="button" :to="{ name: 'driver-management-add-driver' }">Add driver</router-link>
      </div>

      <ManagementTabs type="driver" />
    </header>

    <aside class="highlights">
      <QuickStats period="month" />
      <QuickStats period="week" />
      <router-link class="stat-link" :to="{ name: 'fleet-stats' }">View statistics</router-link>
    </aside>

    <table class="big full-width controlled" ref="table">
      <thead>
        <tr>
          <td class="textual">Driver</td>
          <td class="textual">Groups</td>
          <td v-if="isCorporate" class="textual">Cable ID</td>
          <td class="textual">Car <abbr title="registration">reg</abbr></td>
          <td class="textual">Car model</td>
          <td class="textual">Year</td>
          <td v-if="isCorporate" class="textual">Tariff</td>
        </tr>
      </thead>
      <tbody ref="table-body">
        <tr class="filters">
          <td class="textual">
            <input type="search" :value="nameFilter" @input="changeNameFilter" placeholder="Search…" />
          </td>
          <td class="textual"><GroupSelect type="user" v-model="groupFilter" hide-empty placeholder="Select…" /></td>
          <td v-if="isCorporate" class="textual">
            <input type="search" :value="cableFilter" @input="changeCableFilter" placeholder="Search…" />
          </td>
          <td class="textual">
            <input type="search" :value="regFilter" @input="changeRegFilter" placeholder="Search…" />
          </td>
          <td class="textual">
            <input type="search" :value="modelFilter" @input="changeModelFilter" placeholder="Search…" />
          </td>
          <td class="textual">
            <input type="search" :value="yearFilter" @input="changeYearFilter" placeholder="Search…" />
          </td>
          <td v-if="isCorporate" class="textual">
            <input type="search" :value="tariffFilter" @input="changeTariffFilter" placeholder="Search…" />
          </td>
        </tr>
        <tr v-if="visibleUsers.length == 0">
          <td :colspan="isCorporate ? 7 : 5" class="empty">
            {{ isFiltered ? "No records matching the criteria were found" : "No data to display" }}
          </td>
        </tr>
        <tr v-else v-for="(u, i) in visibleUsers" :key="u.id" :class="{ inactive: !u.active, invited: u.invited }">
          <template v-if="isRowHidden(i)">
            <th>
              <router-link :to="{ name: 'driver-management-driver-details', params: { userId: u.id } }">{{
                u.name
              }}</router-link>
            </th>
            <td :colspan="isCorporate ? 6 : 4">&nbsp;</td>
          </template>
          <template v-else>
            <th class="user textual">
              <router-link :to="{ name: 'driver-management-driver-details', params: { userId: u.id } }">
                <Portrait :src="u.photo" class="portrait" /> <Abbreviated :value="u.name" :max-length="27" />
                <Parenthetical v-if="u.invited" class="badge">invited</Parenthetical>
                <Parenthetical v-else-if="!u.active" class="badge">inactive</Parenthetical>
              </router-link>
            </th>
            <td class="textual groups">
              <GroupTags type="user" baseRoute="driver-management" :ids="u.groupIds" :collapsed="true" />
            </td>
            <td v-if="isCorporate" class="textual">
              <Abbreviated middle :value="u.cable" :max-length="17" style="margin-right: 0.5rem" />
              <router-link
                v-if="u.cable && allowUnlinking"
                :to="{
                  name: 'driver-management-driver-details',
                  params: { userId: u.id },
                  query: { confirmUnlink: 'true' },
                }"
              >
                <UnlinkSVG class="svg-icon" />
              </router-link>
            </td>
            <td class="textual">{{ u.registration }}</td>
            <td class="model textual">{{ u.model }}</td>
            <td class="textual">{{ u.year }}</td>
            <td v-if="isCorporate" class="textual"><Abbreviated :value="u.tariff" :max-length="17" class="last" /></td>
          </template>
        </tr>
      </tbody>
    </table>

    <transition
      name="modal"
      @before-leave="beforeModalLeaves"
      @before-enter="beforeModalEnters"
      @enter="whenModalEnters"
      @leave="whenModalLeaves"
      @after-leave="afterModalLeaves"
      @after-enter="afterModalEnters"
    >
      <router-view />
    </transition>
  </main>
</template>

<script>
import { debounce } from "debounce";
import { mapGetters, mapActions } from "vuex";

import Abbreviated from "elements/Abbreviated";
import ManagementTabs from "elements/ManagementTabs";
import GroupSelect from "elements/GroupSelect";
import GroupTags from "elements/GroupTags";
import Scroller from "elements/Scroller";
import Portrait from "elements/Portrait";
import QuickStats from "elements/QuickStats";
import Parenthetical from "elements/Parenthetical";

import UnlinkSVG from "elements/SVGs/Unlink.vue";

import { HiddenRows, Downloader, ModalOwner } from "~/mixins";

export default {
  mixins: [HiddenRows, Downloader, ModalOwner],
  components: {
    Abbreviated,
    ManagementTabs,
    GroupSelect,
    GroupTags,
    Scroller,
    Portrait,
    QuickStats,
    Parenthetical,
    UnlinkSVG,
  },
  data() {
    return {
      nameFilter: null,
      cableFilter: null,
      regFilter: null,
      modelFilter: null,
      yearFilter: null,
      tariffFilter: null,
      groupFilter: null,
    };
  },
  computed: {
    ...mapGetters(["makeLogos"]),
    ...mapGetters("Fleet", ["fleetName", "isCorporate", "allowUnlinking"]),
    ...mapGetters("Users", ["users"]),
    ...mapGetters("Groups", ["userGroupFilterIds"]),
    fleet() {
      return this.users.map(function (user) {
        return {
          id: user.id,
          active: user.active,
          invited: user.invited,
          name: user.name,
          groupIds: user.groupIds,
          photo: user.photo,
          cable: user.cable.id,
          registration: user.car.registration,
          make: user.car.make,
          model: user.car.make + " " + user.car.model,
          year: user.car.year,
          tariff: user.tariff.name,
        };
      });
    },
    makes() {
      const makes = {};

      this.fleet.forEach((car) => {
        const make = car.make;

        if (make == "") return;

        if (makes[make] != null) makes[make].count++;
        else
          makes[make] = {
            logo: car.logo == null ? this.defaultMakeLogo(car.make) : car.logo,
            make: make,
            count: 1,
          };
      });

      return Object.values(makes).sort((a, b) => (a.make < b.make ? -1 : 1));
    },
    fleetSize() {
      return this.makes.reduce((s, m) => s + m.count, 0);
    },
    visibleUsers() {
      const matches = function (needle, haystack) {
        return `${haystack}`.toLowerCase().indexOf(needle.trim().toLowerCase()) !== -1;
      };
      const userGroupIds = this.userGroupFilterIds(this.groupFilter);

      return this.fleet.filter((car) => {
        let pass = true;

        if (this.nameFilter != null) pass &= matches(this.nameFilter, car.name);
        if (this.cableFilter != null) pass &= matches(this.cableFilter, car.cable);
        if (this.regFilter != null) pass &= matches(this.regFilter, car.registration);
        if (this.modelFilter != null) pass &= matches(this.modelFilter, car.model);
        if (this.yearFilter != null) pass &= matches(this.yearFilter, car.year);
        if (this.tariffFilter != null) pass &= matches(this.tariffFilter, car.tariff);
        if (this.groupFilter)
          pass &= car.groupIds.reduce((result, id) => result || userGroupIds.indexOf(id) != -1, false);

        return pass;
      });
    },
    isFiltered() {
      return (
        this.nameFilter != null ||
        this.cableFilter != null ||
        this.regFilter != null ||
        this.modelFilter != null ||
        this.yearFilter != null ||
        this.tariffFilter != null ||
        this.groupFilter
      );
    },
  },
  methods: {
    ...mapActions("Users", ["deleteUserById"]),
    ...mapActions("Notifications", ["showErrorMessage", "showWarningMessage", "showSuccessMessage"]),

    changeNameFilter: debounce(function (e) {
      this.nameFilter = e.target.value;
    }, 300),
    changeCableFilter: debounce(function (e) {
      this.cableFilter = e.target.value;
    }, 300),
    changeRegFilter: debounce(function (e) {
      this.regFilter = e.target.value;
    }, 300),
    changeModelFilter: debounce(function (e) {
      this.modelFilter = e.target.value;
    }, 300),
    changeYearFilter: debounce(function (e) {
      this.yearFilter = e.target.value;
    }, 300),
    changeTariffFilter: debounce(function (e) {
      this.tariffFilter = e.target.value;
    }, 300),

    ...mapActions(["exportUserData"]),
    downloadUserData(e) {
      this.download(e.target, "fleet.csv", () => this.exportUserData()).then(() => {
        this.showSuccessMessage("All data exported successfully");
      });
    },
    defaultMakeLogo(make) {
      const m = make.toLowerCase();

      if (this.makeLogos[m]) return this.makeLogos[m];
      else
        switch (m) {
          case "tesla":
            return require("~/images/make-tesla.png");
          case "chevrolet":
            return require("~/images/make-chevrolet.png");
          case "tesla":
            return require("~/images/make-tesla.png");
          case "bmw":
            return require("~/images/make-bmw.png");
          case "volkswagen":
            return require("~/images/make-volkswagen.png");
          case "nissan":
            return require("~/images/make-nissan.png");
        }
    },
    filterMake(make) {
      this.nameFilter = null;
      this.cableFilter = null;
      this.regFilter = null;
      this.modelFilter = make;
      this.yearFilter = null;
      this.tariffFilter = null;
      this.groupFilter = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~/common.scss";

table tbody .inactive,
table tbody .invited {
  td,
  th {
    color: $inactive-text;
  }

  .user {
    position: relative;
    padding-top: 28px;
    padding-bottom: 6px;

    .badge {
      position: absolute;
      top: 14px;
      left: 40px;
    }

    a {
      color: $inactive-color;
    }

    .portrait {
      top: -10px;
      opacity: 0.6;
    }
  }
}

table .model > span {
  display: block;
  max-width: 16em;
  overflow: hidden;
  text-overflow: ellipsis;
}

aside.highlights > * + * {
  margin-left: 20px;
}
</style>

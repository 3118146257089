import Vue from "vue";
import {
  createUser,
  updateUser,
  deleteUser,
  updateUserEmail,
  updateUserReimbursementSettings,
  unlinkCharger,
  getSingleUserParsed
} from "~/api";

export const state = {
  users: {
    // "id": {
    //   id: "",
    //   externalId: "",
    //   active: true,
    //   invited: false,
    //   name: "",
    //   photo: "",
    //   email: "",
    //   phone: "",
    //   driverLicence: "",
    //   fuelCard: "",
    //   RFID: "",
    //   groupIds: [""],
    //   homeAddress: "",
    //   officeAddress: "",
    //   car: {
    //     registration: "",
    //     notes: "",
    //     logo: "",
    //     make: "",
    //     model: "",
    //     year: ""
    //   },
    //   tariff: {
    //     logo: "",
    //     name: "",
    //     supplier: "",
    //     currency: ""
    //   },
    //   cable: {
    //     id: "",
    //     type: ""
    //   },
    //   reimbursementSettings: {
    //     settlementType: "bank" | "utility",
    //     reimbursementType: "flat-rate" | "mileage" | "energy-cost",
    //     flatRatePerKWh: {
    //       currency: "GBP",
    //       amount: 0.00
    //     }
    //   },
    //   // See api.js#getStatistics().users.* for more info about below
    //   cost: {...},
    //   distance: {...},
    //   reportedDistance: {...},
    //   energy: {...},
    //   co2: {...},
    //   statsByUsage: {...},
    //   statsByLocationAndPeriod: {...},
    //   statsByUsageAndPeriod: {...},
    // }
  },
};

export const getters = {
  userCount: (_, getters) => getters.users.length,
  users: (state) => Object.values(state.users).sort((a, b) => (a.name < b.name ? -1 : 1)),
  getUserById: (state) => (id) => {
    const user = state.users[id];
    return user;
  },
  usersByGroupId: (_, getters) => (groupId) => getters.users.filter((c) => c.groupIds.indexOf(groupId) !== -1),
  userCountByGroupId: (_, getters) => (groupId) => getters.usersByGroupId(groupId).length,
};

export const mutations = {
  reset(state) {
    state.users = {};
  },
  addUser(state, user) {
    Vue.set(state.users, user.id, user);
  },
  patchUser(state, user) {
    const old = state.users[user.id];

    if (old == undefined) {
      Vue.set(state.users, user.id, user);
    } else
      for (let prop in user) {
        if (user.hasOwnProperty(prop)) Vue.set(old, prop, user[prop]);
      }
  },
  removeUser(state, userId) {
    Vue.delete(state.users, userId);
  },
  updateStatistics(state, stats) {
    stats.forEach((s) => {
      const user = state.users[s.userId];

      if (user == null) return;

      Vue.set(user, "cost", s.cost);
      Vue.set(user, "distance", s.distance);
      Vue.set(user, "reportedDistance", s.reportedDistance);
      Vue.set(user, "energy", s.energy);
      Vue.set(user, "co2", s.co2);

      Vue.set(user, "statsByUsage", s.statsByUsage);

      if (user.statsByLocationAndPeriod == null || user.statsByUsageAndPeriod == null) {
        Vue.set(user, "statsByLocationAndPeriod", s.statsByLocationAndPeriod);
        Vue.set(user, "statsByUsageAndPeriod", s.statsByUsageAndPeriod);
      }
    });
  },
};

export const actions = {
  createUser({ rootGetters, dispatch }, data) {
    return createUser(rootGetters["Fleet/fleetId"], data).then((users) => {
      if (data.reimbursementSettings != null) {
        updateUserReimbursementSettings(rootGetters["Fleet/fleetId"], users[0].id, data.reimbursementSettings).then(
          () => dispatch("reloadUsers", null, { root: true })
        );
      } else {
        dispatch("reloadUsers", null, { root: true });
      }

      return users[0];
    });
  },
  updateUser({ rootGetters, dispatch }, data) {
    return updateUser(rootGetters["Fleet/fleetId"], data).then((user) => {
      if (data.reimbursementSettings != null) {
        updateUserReimbursementSettings(rootGetters["Fleet/fleetId"], user.id, data.reimbursementSettings).then(() =>
          dispatch("reloadUsers", null, { root: true })
        );
      } else {
        dispatch("reloadUsers", null, { root: true });
      }

      return user;
    });
  },
  updateUserEmail({ rootGetters, commit }, data) {
    return updateUserEmail(rootGetters["Fleet/fleetId"], data.userId, data.emailAddress).then(() => {
      const u = { id: data.userId, email: data.emailAddress };
      commit("patchUser", u);
      return u;
    });
  },
  deleteUserById({ rootGetters, commit }, userId) {
    return deleteUser(rootGetters["Fleet/fleetId"], userId).then((id) => {
      commit("removeUser", id);
      return id;
    });
  },
  unlinkChargerById({ rootGetters, dispatch }, { chargerId, userId }) {
    const fleetId = rootGetters["Fleet/fleetId"];
    return unlinkCharger(fleetId, chargerId, userId)
      .then(() => {
        dispatch("reloadUsers", null, { root: true });
      })
      .catch((err) => {
        throw err;
      });
  },
  fetchUserById({ rootGetters, commit }, userId) {
    const fleetId = rootGetters["Fleet/fleetId"];
    return getSingleUserParsed(fleetId, userId).then((user) => {
      commit("patchUser", user);
      return user;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
